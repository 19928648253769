import { useEffect } from "react";
import { useData } from "../dataProvider/dataProvider";
import "./header.css";
import WifiIcon from "./wifiicon";
import useMyFetch from "../fetch/fetch";

const Header = () => {
  const { userInfo, menuExtended, setMenuExtended } = useData();
  const { ping } = useMyFetch();

  useEffect(() => {
    const interval = setInterval(() => {
      ping();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <header>
      {menuExtended ? (
        <svg
          width="40px"
          height="40px"
          viewBox="0 0 24 24"
          className="left"
          onClick={() => {
            setMenuExtended(false);
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM8.96965 8.96967C9.26254 8.67678 9.73742 8.67678 10.0303 8.96967L12 10.9394L13.9696 8.96969C14.2625 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0303L13.0606 12L15.0303 13.9697C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73744 15.3232 9.26256 15.3232 8.96967 15.0303C8.67678 14.7374 8.67678 14.2626 8.96967 13.9697L10.9393 12L8.96965 10.0303C8.67676 9.73744 8.67676 9.26256 8.96965 8.96967Z"
            fill="#1C274C"
          />
        </svg>
      ) : (
        <svg
          width="40px"
          height="40px"
          viewBox="0 0 24 24"
          className="left"
          onClick={() => {
            setMenuExtended(true);
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13ZM9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12ZM16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
            fill="#1C274C"
          />
        </svg>
      )}
      <h2>Lottery Management System</h2>
      <div className="right">
        <WifiIcon />
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 12.5C17.7614 12.5 20 10.2614 20 7.5C20 4.73858 17.7614 2.5 15 2.5C12.2386 2.5 10 4.73858 10 7.5C10 10.2614 12.2386 12.5 15 12.5Z"
            fill="#5D6682"
          />
          <path
            opacity="0.5"
            d="M15 26.25C19.8325 26.25 23.75 24.0114 23.75 21.25C23.75 18.4886 19.8325 16.25 15 16.25C10.1675 16.25 6.25 18.4886 6.25 21.25C6.25 24.0114 10.1675 26.25 15 26.25Z"
            fill="#1C274C"
          />
        </svg>
        <p>{userInfo.displayName}</p>
      </div>
    </header>
  );
};

export default Header;
