import React, { useState, useEffect, useRef, useCallback } from "react";
import "./boxDisplay.css";
import ReactLoading from "react-loading";

const BoxDisplay = ({
  data = [],
  actions = [],
  max_height = "calc(100% - 48px)",
  is_loading = false,
  newLottery = {},
  user = 1,
  onClick,
}) => {
  const actionCount = actions.length;
  const [displayData, setDisplayData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(20);
  const [loadingImages, setLoadingImages] = useState({});
  const observer = useRef();

  const lastRowRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisibleCount((prevCount) => prevCount + 5);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    setDisplayData(data.slice(0, visibleCount));
  }, [data, visibleCount]);

  useEffect(() => {
    const newLoadingImages = {};
    data.forEach((box) => {
      newLoadingImages[box.id] = true; // Initialize all images as not loading
    });
    setLoadingImages(newLoadingImages);
  }, []);

  const handleImageLoad = (id) => {
    setLoadingImages((prev) => ({
      ...prev,
      [id]: false, // Set the specific image's loading state to false
    }));
  };

  const handleImageError = (id) => {
    setLoadingImages((prev) => ({
      ...prev,
      [id]: false, // Set the specific image's loading state to false on error
    }));
  };

  const renderAction = (action, id) => {
    const actionType = action.type;
    const handleClick = () => action.handler(id);

    switch (actionType) {
      case "view":
        return (
          <svg onClick={handleClick} title="View">
            <use xlinkHref="#view-icon" />
          </svg>
        );
      case "edit":
        return (
          <svg onClick={handleClick} title="Edit">
            <use xlinkHref="#edit-icon" />
          </svg>
        );
      case "delete":
        return (
          <svg onClick={handleClick} title="Delete">
            <use xlinkHref="#delete-icon" />
          </svg>
        );
      case "download":
        return (
          <svg onClick={handleClick} title="Save">
            <use xlinkHref="#save-icon" />
          </svg>
        );
      default:
        return (
          <button onClick={handleClick} className="success-outline">
            {actionType}
          </button>
        );
    }
  };

  return (
    <div className="box-display" style={{ maxHeight: max_height }}>
      {is_loading ? (
        <div className="loading-indicator">
          <ReactLoading type="bars" color="var(--light)" height={"40px"} width={"40px"} />
        </div>
      ) : (
        <div className="box-content">
          {displayData.map((box) => (
            <div
              key={box.id} // Ensure each element has a unique key prop
              className="boxes"
              ref={box === displayData[displayData.length - 1] ? lastRowRef : null}
            >
              <div className="img-container" onClick={() => onClick(box.id)}>
                {loadingImages[box.id] && (
                  <div className="loading-image">
                    <ReactLoading type="bars" color="var(--light)" height={"40px"} width={"40px"} />
                  </div>
                )}
                <img
                  src={box.img}
                  alt="lottery"
                  onLoad={() => handleImageLoad(box.id)}
                  onError={() => handleImageError(box.id)}
                  style={{ display: loadingImages[box.id] ? "none" : "block" }}
                />
              </div>
              <h3>{box.name}</h3>
              <p>
                Time(s) - <span>{box.time}</span>
              </p>
              <p>
                Price - <span>{Number(box.price).toLocaleString("en-US")} MMK</span>
              </p>
              <p>
                Range -{" "}
                <span>
                  {box.range_start} to {box.range_end}
                </span>
              </p>
              <p>
                Open date - <span>{box.date}</span>
              </p>
              <p>
                Type - <span>{box.type}</span>
              </p>
              <p>
                open - <span>{box.date}</span>
              </p>
              {user == "0" && actionCount > 0 && (
                <div className={`action`}>
                  {actions.map((action, actionIndex) => (
                    <div key={actionIndex}>{renderAction(action, box.id)}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
          {user == "0" && (
            <div className="add-new-lottery" onClick={newLottery}>
              <svg title="New Lottery">
                <use xlinkHref="#plus-dark-icon" />
              </svg>
              <h4>Create Lottery</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BoxDisplay;
