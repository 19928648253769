// DataContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import useMyFetch from "../fetch/fetch";
import toast from "react-hot-toast";

const DataContext = createContext();

const getUserInfo = () => {
  const storedData = localStorage.getItem("userInfo");
  // console.log(storedData);
  return storedData ? JSON.parse(storedData) : { key: "", id: "", displayName: "", role: 1 };
};

const getDeviceAuth = () => {
  const storedData = localStorage.getItem("deviceAuth");
  return storedData ? JSON.parse(storedData) : { key: "", id: "" };
};

export const DataProvider = ({ children }) => {
  // const [initialized, setInitialized] = useState(false); // New state for initialization

  const [userInfo, setUserInfo] = useState(getUserInfo);
  const [deviceAuth, setDeviceAuth] = useState(getDeviceAuth);
  const [pingAmount, setPingAmount] = useState(5);
  const [connectionQuality, setConnectionQuality] = useState(4);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [menuExtended, setMenuExtended] = useState(true);
  const [isTicket, setIsTicket] = useState(0);
  const [ticketInfo, setTicketInfo] = useState(false);
  const [userList, setUserList] = useState([]);
  const [reportData, setReportData] = useState({
    state: 0,
    date: "",
    user: 0,
  });

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  }, [userInfo]);

  useEffect(() => {
    if (deviceAuth) {
      localStorage.setItem("deviceAuth", JSON.stringify(deviceAuth));
    }
  }, [deviceAuth]);

  useEffect(() => {
    if (deviceAuth) {
      localStorage.setItem("deviceAuth", JSON.stringify(deviceAuth));
    }
  }, [deviceAuth]);

  return (
    <DataContext.Provider
      value={{
        userInfo,
        setUserInfo,
        connectionQuality,
        setConnectionQuality,
        pingAmount,
        setPingAmount,
        loadingScreen,
        setLoadingScreen,
        menuExtended,
        setMenuExtended,
        isTicket,
        setIsTicket,
        ticketInfo,
        setTicketInfo,
        deviceAuth,
        setDeviceAuth,
        userList,
        setUserList,
        reportData,
        setReportData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
