import React, { useState, useEffect, useRef, useCallback } from "react";

import logo from "../components/ticket/logo.png";
import ReactLoading from "react-loading";
import "./subTickets.css";
import { SearchBox } from "../components/inputBox/inputBox";
import { useData } from "../components/dataProvider/dataProvider";
import { toPng } from "html-to-image";
import download from "downloadjs"; // Optional for downloading the image
import useMyFetch from "../components/fetch/fetch";
import PopupBox2 from "../components/popup/popup2";
import { useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";

const SubTicket = () => {
  const { post } = useMyFetch();
  const { userInfo, setIsTicket, ticketInfo, setLoadingScreen } = useData();
  const [isLoading, setIsLoading] = useState(false); // Initially set to true to show loading
  const [search, setSearch] = useState("");
  const [tickets, setTickets] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(150);
  const [ticketsData, setTicketsData] = useState([]);
  const [currentTicketNumber, setCurrentTicketNumber] = useState(false);

  const [currentTime, setCurrentTime] = useState();

  const observer = useRef();
  const containerRef = useRef(null);

  const generateArray = (start, end) => {
    let array = [];
    const maxLength = Math.max(String(start).length, String(end).length);
    const padLength = Math.max(maxLength, 2);

    for (let i = start; i <= end; i++) {
      let formattedNumber = String(i).padStart(padLength, "0");
      array.push(formattedNumber);
    }
    return array;
  };

  useEffect(() => {
    // Function to fetch ticket data
    const fetchTicketData = () => {
      post("ticket", { lottery: ticketInfo.id }).then((res) => {
        setTicketsData(res.data);
      });
    };

    // Initial fetch and state setup
    fetchTicketData();
    const generatedTickets = generateArray(ticketInfo.range_start, ticketInfo.range_end);
    setTickets(generatedTickets);
    setDisplayData(generatedTickets.slice(0, visibleCount));

    // const intervalId = setInterval(() => {
    //   fetchTicketData();
    // }, 5000);

    // return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Filter tickets based on search input
    // console.log(Math.max(0, visibleCount - 200), visibleCount);
    const filteredTickets = tickets.filter((num) => num.includes(search.toUpperCase()));
    setDisplayData(filteredTickets.slice(0, visibleCount));
  }, [search]);

  useEffect(() => {
    setDisplayData(tickets.slice(0, visibleCount));
  }, [tickets, visibleCount]);

  const lastRowRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // if (visibleCount + 30 < tickets.length) {
          setVisibleCount((prevCount) => prevCount + 30);
          // } else {
          // setVisibleCount(tickets.length);
          // }
        }
      });
      if (node) observer.current.observe(node);
    },
    // [tickets.length, visibleCount]
    []
  );

  const handleExportClick = () => {
    if (containerRef.current) {
      setLoadingScreen(true);
      const currentDate = new Date();
      setCurrentTime(currentDate.toLocaleString());
      // Split tickets into chunks of 1000
      const chunkSize = 1000;
      const ticketChunks = [];
      for (let i = 0; i < tickets.length; i += chunkSize) {
        ticketChunks.push(tickets.slice(i, i + chunkSize));
      }

      const exportChunk = (chunk, chunkIndex) => {
        setTimeout(() => {
          const div = document.createElement("div");
          div.classList.add("export-ticket-boxes");
          chunk.forEach((item) => {
            const p = document.createElement("p");
            p.innerText = item;
            const exit = ticketsData.find((ticket) => ticket.ticket_number == parseInt(item));

            // Determine the className based on the state
            let className = "outline"; // Default className

            if (exit) {
              if (parseInt(exit.state) === 1) {
                className = "danger";
              } else if (parseInt(exit.state) === 2) {
                className = "warning";
              }
            }
            p.classList.add(className);
            div.appendChild(p);
          });

          containerRef.current.appendChild(div);
          containerRef.current.style.display = "block";
          toPng(containerRef.current)
            .then((dataUrl) => {
              download(dataUrl, currentDate.toLocaleString() + `part${chunkIndex + 1}.png`);
              containerRef.current.removeChild(div);
              containerRef.current.style.display = "none";

              // Process the next chunk
              if (chunkIndex + 1 < ticketChunks.length) {
                exportChunk(ticketChunks[chunkIndex + 1], chunkIndex + 1);
              } else {
                setLoadingScreen(false);
              }
            })
            .catch((error) => {
              console.error("Error exporting image: ", error);
              setLoadingScreen(false);
            });
        }, 50);
      };

      // Start exporting the first chunk
      if (ticketChunks.length > 0) {
        exportChunk(ticketChunks[0], 0);
      }
    }
  };

  const ticketDetail = (id) => {
    console.log(parseInt(id));
    setLoadingScreen(true);
    setCurrentTicketNumber(id);
  };
  function getOrdinalSuffixOnly(n) {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  }

  const openReport = () => {
    const id = ticketInfo.id;
    console.log(id);
    // setMenuExtended(false);
    setIsTicket(2);
  };

  return (
    <div className="tickets">
      <div className="ticket-title">
        <svg
          className="back-btn"
          height="32px"
          width="32px"
          viewBox="0 0 219.151 219.151"
          onClick={() => {
            setIsTicket(0);
            setTimeout(() => {
              // setMenuExtended(true);
            }, 150);
          }}
          style={{ marginTop: "20px" }}
        >
          <path
            d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575
            C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575
            c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"
          />
          <path
            d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
            c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
            c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
            c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"
          />
        </svg>
        <div className="inner">
          <div className="ticket-info">
            <div className="left">
              <h3>{ticketInfo.name}</h3>
              <p>
                Time(s) - <span>{ticketInfo.time}</span>
              </p>
              <p>
                Price - <span>{Number(ticketInfo.price).toLocaleString("en-US")} MMK</span>
              </p>
              <p>
                Range -{" "}
                <span>
                  {ticketInfo.range_start} to {ticketInfo.range_end}
                </span>
              </p>
              <p>
                Open Date - <span>{ticketInfo.date}</span>
              </p>
              <p>
                Type - <span>{ticketInfo.type}</span>
              </p>
            </div>
            <div className="right">
              {isLoading ? (
                <div className="loading-image">
                  <ReactLoading type="bars" color="var(--primary)" height={"40px"} width={"40px"} />
                </div>
              ) : (
                <img
                  src={ticketInfo.img}
                  alt=""
                  onLoad={() => setIsLoading(false)}
                  onError={() => setIsLoading(false)}
                />
              )}
            </div>
          </div>
          <div className="ticket-right">
            <div className="color-box-container">
              <div className="color-box">
                <div className="outline"></div>
                <div className="text">
                  <p>Available</p>
                  <p>
                    (
                    {ticketInfo.range_end -
                      ticketInfo.range_start -
                      ticketsData.filter((ticket) => ticket.state === 1).length +
                      1}
                    )
                  </p>
                </div>
              </div>
              <div className="color-box">
                <div className="danger"></div>
                <div className="text">
                  <p>Sold</p>
                  <p>({ticketsData.filter((ticket) => ticket.state === 1).length})</p>
                </div>
              </div>
              <div className="color-box">
                <div className="warning"></div>
                <div className="text">
                  <p>Reserved</p>
                  <p>
                    (
                    {
                      Array.from(
                        new Set(
                          ticketsData
                            .filter((ticket) => ticket.state === 2)
                            .map((ticket) => ticket.ticket_number)
                        )
                      ).length
                    }
                    )
                  </p>
                </div>
              </div>
            </div>
            <div className="action-container">
              {/* <div className="search"> */}
              <SearchBox
                type="text"
                value={search}
                setValue={setSearch}
                placeholder="Search"
                className="tickerSearch"
              />
              <button className="success save-button" onClick={handleExportClick}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.8254 19.4118C25.2848 19.4118 26.4724 20.5994 26.4724 22.0588V27.3529C26.4724 28.8124 25.2848 30 23.8254 30H6.17831C4.71889 30 3.53125 28.8123 3.53125 27.3529V22.0588C3.53125 20.5994 4.71891 19.4118 6.17831 19.4118H23.8254ZM15.8842 0V5.29413H14.1195V0H15.8842ZM8.82537 22.9412C9.79948 22.9412 10.5901 23.7335 10.5901 24.7059C10.5901 25.6782 9.79948 26.4706 8.82537 26.4706C7.85125 26.4706 7.06066 25.6783 7.06066 24.7059C7.06066 23.7335 7.85125 22.9412 8.82537 22.9412ZM14.1195 5.29413V11.9876L10.2707 8.13883L9.023 9.38648L15.0018 15.3653L20.9807 9.38647L19.733 8.13881L15.8842 11.9876V5.29413H23.8254C25.2901 5.29413 26.4724 6.47647 26.4724 7.94117V18.5294C25.7489 17.9824 24.8136 17.6471 23.8254 17.6471H6.17831C5.19008 17.6471 4.25478 17.9824 3.53125 18.5294V7.94117C3.53125 6.47648 4.71359 5.29413 6.17831 5.29413H14.1195Z"
                    fill="white"
                  />
                </svg>

                <p>Save</p>
              </button>
              {parseInt(userInfo.role) === 0 && (
                <button className="secondary" onClick={openReport}>
                  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.8"
                      d="M27.8571 25.5864C27.8571 26.8348 26.836 27.856 25.5875 27.856H4.41478C3.16631 27.856 2.14515 26.8348 2.14515 25.5864V4.41367C2.14515 3.1652 3.16631 2.14404 4.41478 2.14404H25.5875C26.836 2.14404 27.8571 3.1652 27.8571 4.41367V25.5864ZM27.8571 10.2224L25.535 8.658C25.5817 8.4575 25.6096 8.24884 25.6096 8.03318C25.6096 6.52476 24.3868 5.30077 22.8772 5.30077C21.3676 5.30077 20.1448 6.5236 20.1448 8.03318C20.1448 8.62653 20.336 9.17441 20.6577 9.62321L15.637 17.3005C15.433 17.2516 15.2197 17.2224 15.0006 17.2224C14.3279 17.2224 13.7136 17.4661 13.2368 17.8694L9.87611 15.9355C9.8936 15.8119 9.90525 15.6872 9.90525 15.559C9.90525 14.0506 8.68243 12.8266 7.17284 12.8266C5.66325 12.8266 4.44043 14.0494 4.44043 15.559C4.44043 15.9332 4.5162 16.2899 4.65258 16.6151L2.14282 18.943V21.4201L5.87075 17.9615C6.25776 18.1713 6.70073 18.2902 7.17167 18.2902C7.89441 18.2902 8.54954 18.0081 9.03797 17.5488L12.3171 19.4361C12.2845 19.604 12.267 19.7765 12.267 19.9537C12.267 21.4621 13.4898 22.6849 14.9994 22.6849C16.509 22.6849 17.7318 21.4621 17.7318 19.9537C17.7318 19.3254 17.5173 18.7483 17.1606 18.2867L22.1463 10.663C22.3795 10.7271 22.6231 10.7644 22.8761 10.7644C23.509 10.7644 24.0896 10.5476 24.5535 10.1862L27.8559 12.4116L27.8571 10.2224Z"
                      fill="#fff"
                    />
                  </svg>
                  <p>View Report</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ticket-body">
        <h3>Tickets</h3>
        <div className="ticket-boxes">
          {displayData.map((num, index) => {
            // Find the ticket based on ticket_number
            const ticket = ticketsData.find(
              (ticket) => parseInt(ticket.ticket_number) === parseInt(num)
            );

            // Determine the className based on the state
            let className = "outline"; // Default className

            if (ticket) {
              if (parseInt(ticket.state) === 1) {
                className = "danger";
              } else if (parseInt(ticket.state) === 2) {
                className = "warning";
              }
            }

            return (
              <p
                key={index}
                ref={num == displayData.length - 1 ? lastRowRef : null}
                onClick={() => ticketDetail(num)}
                className={className}
              >
                {num}
              </p>
            );
          })}
          {/* {isLoading && (
            <div className="loading-image">
              <ReactLoading type="bars" color="var(--primary)" height={"40px"} width={"40px"} />
            </div>
          )} */}
        </div>
      </div>
      {currentTicketNumber && (
        <PopupBox2
          ticket_number={currentTicketNumber}
          color="primary"
          close={() => setCurrentTicketNumber(false)}
          setTicketsData={setTicketsData}
          ticketsData={ticketsData}
        ></PopupBox2>
      )}
      <div
        ref={containerRef}
        style={{
          backgroundColor: "#fff",
          width: "1060px",
          height: "fit-content",
          padding: "20px",
          display: "none",
        }}
        className="export-container"
      >
        <div>
          <div
            style={{
              paddingBottom: "20px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>09-777 772 142 (Viber) </h3>
            <div style={{ display: "flex", gap: "24px", alignItems: "center" }}>
              <img
                src={logo}
                alt=""
                style={{ width: "100px", height: "50px", objectFit: "cover" }}
              />
              <h3>
                {ticketInfo.time}
                <sup>{getOrdinalSuffixOnly(ticketInfo.time)} </sup> - Ko Thaw Lucky Draw
              </h3>
            </div>
            <h3>{currentTime}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubTicket;
