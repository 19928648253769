import { useEffect, useRef, useState } from "react";
import ListDisplay from "../components/listDisplay/listDisplay";
import "./employees.css";
import useMyFetch from "../components/fetch/fetch";
import PopupBox from "../components/popup/popup";
import { InputBox2, TextArea, SmallGroup } from "../components/inputBox/inputBox";
import toast from "react-hot-toast";
import { useData } from "../components/dataProvider/dataProvider";

const Customers = () => {
  const { post } = useMyFetch();
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState({
    newCustomer: false,
    editCustomer: false,
    deleteCustomer: false,
  });
  const [loading, setLoading] = useState(true);
  const { setLoadingScreen } = useData();

  useEffect(() => {
    post("customer").then((response) => {
      if (response.status !== "success") {
        toast.error(response.message);
        return;
      }
      if (Array.isArray(response.data)) {
        setData(response.data);
        setLoading(false);
      } else {
        const dataArray = Object.keys(response.data).map((key) => data[key]);
        setData(dataArray);
      }
    });
  }, []);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState([]);
  const [duplicateIndices, setDuplicateIndices] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);

  const nameInput = useRef(null);
  const phoneInput = useRef(null);
  const addressInput = useRef(null);
  const numberInput = useRef(null);

  function KeyPressHandler(event, nextRef, isLast = false) {
    if (event.target.name === "phone") {
      if (!event.key.match(/^\d$/)) {
        event.preventDefault();
      }
    }
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value !== "") {
        if (event.target.name === "address") {
          numberInput.current.childNodes[numberInput.current.childNodes.length - 2].focus();
          return;
        }
        if (isLast) {
          if (showPopup.editBlacklist) {
            confirmEdit();
            return;
          }
          ConfirmNewCustomer();
          return;
        }
        if (nextRef && nextRef.current) {
          nextRef.current.focus();
        }
      }
    }
  }

  const inputCheck = () => {
    if (name === "") {
      toast.error("Name Cannot be Empty");
      nameInput.current.focus();
      return false;
    }
    if (phoneNumber === "") {
      toast.error("Phone Number Cannot be Empty");
      phoneInput.current.focus();
      return false;
    }
    if (address === "") {
      toast.error("Address Cannot be Empty");
      addressInput.current.focus();
      return false;
    }
    if (number.length === 1 && number[0] !== "0") {
      toast.error("Ticket Number cannot be Empty");
      numberInput.current.childNodes[0].focus();
      return false;
    }
    return true;
  };

  const checkDuplicates = () => {
    const occurrences = {};
    const duplicates = [];

    number.forEach((v, index) => {
      if (occurrences[v] !== undefined) {
        duplicates.push(occurrences[v]);
        duplicates.push(index);
      } else {
        occurrences[v] = index;
      }
    });

    setDuplicateIndices(duplicates);
    if (duplicates.length > 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 200);
      toast.error(" Repeating Ticket Numbers");
      console.log(duplicates);
      numberInput.current.childNodes[duplicates[duplicates.length - 1]].focus();
      return true;
    }
    return false;
  };

  const newCustomer = () => {
    console.log("new Employee");
    setName("");
    setPhoneNumber("");
    setAddress("");
    setNumber([""]);
    setDuplicateIndices([]);
    setShowPopup({ ...showPopup, newCustomer: true });
  };
  useEffect(() => {
    console.log(duplicateIndices);
  }, [duplicateIndices]);
  useEffect(() => {
    console.log(isAnimating);
  }, [isAnimating]);
  const ConfirmNewCustomer = () => {
    if (!inputCheck()) return;
    if (checkDuplicates()) return;
    setLoadingScreen(true);
    console.log("Confirm new Employee");
    const Number = number
      .filter((value) => value !== undefined && value !== null && value !== "")
      .join(",");
    post("customer/add", { name, ph_no: phoneNumber, address: address, number: Number }).then(
      (response) => {
        setLoadingScreen(false);
        if (response.status !== "success") {
          toast.error(response.message);
          if (response.message === "A Customer with that phone number \n already exist")
            phoneInput.current.focus();
          return;
        }
        setData([...data, response.data]);
        setShowPopup({ ...showPopup, newCustomer: false });
      }
    );
  };

  const handleEdit = (id) => {
    console.log("Edit action triggered for ID:", id);
    setId(id);
    const Customer = data.filter((item) => item.id === id)[0];
    console.log(Customer);
    setName(Customer.name);
    setPhoneNumber(Customer.ph_no);
    setAddress(Customer.address);
    setNumber(
      Customer.number.split(",").map((item) => {
        if (/^0\d+$/.test(item)) {
          return item;
        } else {
          return Number(item);
        }
      })
    );
    setDuplicateIndices([]);

    setShowPopup({ ...showPopup, editCustomer: true });
  };
  const confirmEdit = () => {
    console.log("confirm Edit");
    if (!inputCheck()) return;
    if (checkDuplicates()) return;
    setLoadingScreen(true);
    const Number = number
      .filter((value) => value !== undefined && value !== null && value !== "")
      .join(",");
    post("customer/edit", { id, name, ph_no: phoneNumber, address: address, number: Number }).then(
      (response) => {
        setLoadingScreen(false);
        if (response.status !== "success") {
          toast.error(response.message);
          if (response.message === "Phone Number already exist") phoneInput.current.focus();
          return;
        }
        setData(data.map((item) => (item.id === id ? response.data : item)));
        setShowPopup({ ...showPopup, editCustomer: false });
      }
    );
  };

  const handleDelete = (id) => {
    console.log("Delete action triggered for", id);
    setId(id);
    setShowPopup({ ...showPopup, deleteCustomer: true });
  };
  const ConfirmDeleteCustomer = () => {
    console.log("Confirm Delete Trigger for", id);
    setLoadingScreen(true);
    post("customer/delete", { id: id }).then((response) => {
      setLoadingScreen(false);
      if (response.status !== "success") {
        toast.error(response.message);
        return;
      }
      setData(data.filter((item) => item.id !== id));
      setShowPopup({ ...showPopup, deleteCustomer: false });
    });
  };

  return (
    <div className="pages employees">
      <div className="page-title">
        <h2>Customer</h2>
        <div className="action">
          <button className="secondary" onClick={newCustomer}>
            <svg width="16px">
              <use xlinkHref="#plus-icon" />
            </svg>
            <p>Add Customer</p>
          </button>
        </div>
      </div>

      <ListDisplay
        titleList={["Name", "Phone Number", "Address", "Ticket No:"]}
        size={["name", "ph-no", "address", "name-warp"]}
        data={data.map((Customer) => ({
          id: Customer.id,
          Name: Customer.name,
          "Phone Number": Customer.ph_no.replace(/(\d{2})(\d{7})/, "$1 $2"),
          Address: Customer.address,
          "Ticket No:": Customer.number
            .split(",")
            .map((part) => part.trim())
            .join(", "),
        }))}
        actions={[
          { type: "edit", handler: handleEdit },
          { type: "delete", handler: handleDelete },
        ]}
        is_loading={loading}
      />

      {(showPopup.editCustomer || showPopup.newCustomer) && (
        <PopupBox
          formName="new-Customer"
          color="primary"
          close={() => setShowPopup({ ...showPopup, newCustomer: false, editCustomer: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            {
              type: "success",
              name: showPopup.editCustomer ? "Save" : "Create",
              func: showPopup.editCustomer ? confirmEdit : ConfirmNewCustomer,
            },
          ]}
          style={{ width: "600px" }}
        >
          <h1>{showPopup.newCustomer ? "Add Customer" : "Edit Customer"}</h1>
          <svg title="employee-icon" width="88px" height="88px">
            <use xlinkHref="#employee-icon" />
          </svg>
          <InputBox2
            type="text"
            name="name"
            display="Name"
            value={name}
            setValue={setName}
            isRequired={true}
            ref={nameInput}
            onKeyPress={(e) => KeyPressHandler(e, phoneInput)}
            placeholder="Enter Customer Name"
            autoComplete="off"
          />
          <InputBox2
            type="text"
            name="phone"
            display="Phone Number"
            value={phoneNumber}
            setValue={setPhoneNumber}
            isRequired={true}
            ref={phoneInput}
            onKeyPress={(e) => KeyPressHandler(e, addressInput)}
            placeholder="Enter Username"
            autoComplete="off"
          />

          <TextArea
            name="address"
            display="Address"
            value={address}
            setValue={setAddress}
            isRequired={true}
            ref={addressInput}
            onKeyPress={(e) => KeyPressHandler(e, numberInput.current.childNodes[0])}
            placeholder="Enter Address"
            autoComplete="off"
          />

          <SmallGroup
            name="number"
            display="Ticket Numbers"
            value={number}
            setValue={setNumber}
            highlightIndices={duplicateIndices}
            isAnimating={isAnimating}
            ref={numberInput}
          />
        </PopupBox>
      )}
      {showPopup.deleteCustomer && (
        <PopupBox
          formName="delete-Customer row"
          color="danger"
          close={() => setShowPopup({ ...showPopup, deleteCustomer: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            {
              type: "danger",
              name: "Delete",
              func: ConfirmDeleteCustomer,
            },
          ]}
        >
          <div className="trash-can">
            <svg viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.31258 25.8345C2.31258 27.5489 3.76571 28.9516 5.54175 28.9516H18.4584C20.2345 28.9516 21.6876 27.5489 21.6876 25.8345V7.13172H2.31258V25.8345ZM23.3022 2.45603H17.6511L16.0365 0.897461H7.96362L6.34904 2.45603H0.697998V5.57315H23.3022V2.45603Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="right">
            <h3>Are you sure?</h3>
            <p>
              Do you really want to delete this Customer?
              <br /> This process cannot be undone.
            </p>
          </div>
        </PopupBox>
      )}
    </div>
  );
};

export default Customers;
