// import React, { Component } from "react";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import "./navbar.css";
import PopupBox from "../popup/popup";
import { useState } from "react";
import { useData } from "../dataProvider/dataProvider";

const NavBar = () => {
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState({ logout: false });
  const { userInfo, setUserInfo, menuExtended } = useData();
  const handleLogout = () => {
    console.log("Logging Out");
    setUserInfo({ key: "", id: "", displayName: "" });
    navigate("/login");
  };
  return (
    <nav className={menuExtended ? "extended" : ""}>
      <ul>
        <NavLink to="/tickets">
          <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M17.5171 21.25L17.5099 23.7536C17.5081 24.3414 17.5072 24.6351 17.3239 24.8176C17.1405 25 16.8459 25 16.2567 25H12.4938C7.76797 25 5.40509 25 3.93697 23.5355C2.92614 22.5273 2.61129 21.0958 2.51322 18.7631C2.49377 18.3006 2.48405 18.0693 2.57042 17.915C2.65681 17.7606 3.00166 17.5681 3.69135 17.1829C4.45732 16.7551 4.97509 15.9379 4.97509 15C4.97509 14.0621 4.45732 13.2449 3.69135 12.8171C3.00166 12.4319 2.65681 12.2393 2.57042 12.085C2.48405 11.9307 2.49377 11.6994 2.51322 11.2368C2.61129 8.90424 2.92614 7.47279 3.93697 6.46446C5.40509 5 7.76799 5 12.4938 5H16.8815C17.2267 5 17.507 5.27872 17.508 5.62319L17.5171 8.75C17.5171 9.44035 18.0781 10 18.7702 10V12.5C18.0781 12.5 17.5171 13.0596 17.5171 13.75V16.25C17.5171 16.9404 18.0781 17.5 18.7702 17.5V20C18.0781 20 17.5171 20.5596 17.5171 21.25Z"
              className="nav-color-primary"
            />
            <path
              opacity="0.5"
              d="M18.7708 19.9998C19.4629 19.9998 20.0239 20.5594 20.0239 21.2498V23.7204C20.0239 24.3223 20.0239 24.6233 20.2169 24.8078C20.4099 24.9924 20.7051 24.9799 21.2958 24.9549C23.6244 24.8563 25.0551 24.5413 26.0635 23.5353C27.0744 22.527 27.3893 21.0955 27.4873 18.763C27.5068 18.3004 27.5165 18.069 27.4301 17.9148C27.3438 17.7604 26.9989 17.5679 26.3091 17.1828C25.5433 16.755 25.0254 15.9376 25.0254 14.9998C25.0254 14.0619 25.5433 13.2446 26.3091 12.8169C26.9989 12.4317 27.3438 12.2391 27.4301 12.0848C27.5165 11.9305 27.5068 11.6992 27.4873 11.2366C27.3893 8.90402 27.0744 7.47257 26.0635 6.46424C24.9664 5.36983 23.3695 5.09329 20.6603 5.0234C20.3108 5.01439 20.0239 5.29625 20.0239 5.645V8.74978C20.0239 9.44014 19.4629 9.99978 18.7708 9.99978V12.4998C19.4629 12.4998 20.0239 13.0594 20.0239 13.7498V16.2498C20.0239 16.9401 19.4629 17.4998 18.7708 17.4998V19.9998Z"
              fill="var(--nav-base)"
              className="nav-color-base"
            />
          </svg>
          <p>Tickets</p>
        </NavLink>

        <NavLink to="/customers">
          <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 12.5C17.7614 12.5 20 10.2614 20 7.5C20 4.73858 17.7614 2.5 15 2.5C12.2386 2.5 10 4.73858 10 7.5C10 10.2614 12.2386 12.5 15 12.5Z"
              className="nav-color-primary"
            />
            <path
              opacity="0.5"
              d="M15 26.25C19.8325 26.25 23.75 24.0114 23.75 21.25C23.75 18.4886 19.8325 16.25 15 16.25C10.1675 16.25 6.25 18.4886 6.25 21.25C6.25 24.0114 10.1675 26.25 15 26.25Z"
              className="nav-color-base"
            />
          </svg>
          <p>Customers</p>
        </NavLink>

        <NavLink to="/blacklists">
          <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 12.5C17.7614 12.5 20 10.2614 20 7.5C20 4.73857 17.7614 2.5 15 2.5C12.2386 2.5 10 4.73857 10 7.5C10 10.2614 12.2386 12.5 15 12.5Z"
              className="nav-color-primary"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.625 19.6875C18.7265 19.6875 17.1875 21.2265 17.1875 23.125C17.1875 23.7505 17.354 24.3364 17.6459 24.8415L22.3415 20.1459C21.8364 19.854 21.2505 19.6875 20.625 19.6875ZM23.6495 21.4895L18.9895 26.1495C19.4757 26.4131 20.0326 26.5625 20.625 26.5625C22.5235 26.5625 24.0625 25.0235 24.0625 23.125C24.0625 22.5326 23.9131 21.9758 23.6495 21.4895ZM15.3125 23.125C15.3125 20.191 17.691 17.8125 20.625 17.8125C22.1112 17.8125 23.4565 18.4241 24.4196 19.407C25.3576 20.3643 25.9375 21.6779 25.9375 23.125C25.9375 26.059 23.559 28.4375 20.625 28.4375C19.1779 28.4375 17.8643 27.8576 16.907 26.9196C15.9241 25.9565 15.3125 24.6112 15.3125 23.125Z"
              className="nav-color-primary"
            />
            <path
              opacity="0.4"
              d="M22.4954 18.1514C21.9135 17.9324 21.2831 17.8125 20.625 17.8125C17.691 17.8125 15.3125 20.191 15.3125 23.125C15.3125 24.6112 15.9241 25.9565 16.907 26.9196C17.0983 27.107 17.3036 27.2801 17.5216 27.4371C16.761 27.4785 15.9231 27.5 15 27.5C5 27.5 5 24.9816 5 21.875C5 18.7684 9.47715 16.25 15 16.25C17.9843 16.25 20.6631 16.9853 22.4954 18.1514Z"
              className="nav-color-base"
            />
          </svg>
          <p>BlackLists</p>
        </NavLink>

        {parseInt(userInfo.role) === 0 && (
          <NavLink to="/employees">
            <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.4053 24.2857C12.3563 24.2857 15 24.2857 15 24.2857C15 24.2857 17.6432 24.2857 18.5947 24.2857C19.5452 24.2857 19.9767 22.9027 19.4266 21.9252C19.0122 21.1886 18.3333 20.3534 17.2284 19.8791C16.596 20.3218 15.8277 20.5822 15 20.5822C14.1713 20.5822 13.404 20.3218 12.7715 19.8791C11.6662 20.3534 10.9877 21.1886 10.5729 21.9252C10.0232 22.9027 10.4543 24.2857 11.4053 24.2857Z"
                className="nav-color-primary-2"
              />
              <path
                d="M15.0001 19.6568C16.6239 19.6568 17.9395 18.3417 17.9395 16.7178V16.0137C17.9395 14.3899 16.624 13.0739 15.0001 13.0739C13.3762 13.0739 12.0602 14.3899 12.0602 16.0137V16.7178C12.0602 18.3417 13.3762 19.6568 15.0001 19.6568Z"
                className="nav-color-primary-2"
              />
              <path
                opacity="0.5"
                d="M23.7291 3.33334H19.2857V4.28573C19.2857 6.12352 17.7902 7.61907 15.9524 7.61907H14.0476C12.2098 7.61907 10.7143 6.12352 10.7143 4.28573V3.33334H6.27094C4.78002 3.33334 3.57141 4.54196 3.57141 6.03282V27.3005C3.57141 28.7914 4.78002 30 6.27094 30H23.7291C25.2199 30 26.4285 28.7914 26.4285 27.3005V6.03282C26.4286 4.54196 25.22 3.33334 23.7291 3.33334ZM23.5714 27.1429H6.42856V8.80952H23.5714V27.1429Z"
                className="nav-color-base-2"
              />
              <path
                d="M14.0476 6.66668H15.9523C17.2652 6.66668 18.3333 5.59852 18.3333 4.28572V2.38096C18.3333 1.06816 17.2651 0 15.9523 0H14.0476C12.7348 0 11.6666 1.06816 11.6666 2.38096V4.28572C11.6666 5.59846 12.7348 6.66668 14.0476 6.66668ZM13.5714 2.38096C13.5714 2.11775 13.7844 1.90477 14.0476 1.90477H15.9523C16.2156 1.90477 16.4285 2.11775 16.4285 2.38096V3.33334C16.4285 3.59654 16.2156 3.80953 15.9523 3.80953H14.0476C13.7844 3.80953 13.5714 3.59654 13.5714 3.33334V2.38096Z"
                className="nav-color-primary-2"
              />
            </svg>
            <p>Employees</p>
          </NavLink>
        )}
        {/* {parseInt(userInfo.role) === 0 && (
          <NavLink to="/report">
            <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.8"
                d="M27.8571 25.5864C27.8571 26.8348 26.836 27.856 25.5875 27.856H4.41478C3.16631 27.856 2.14515 26.8348 2.14515 25.5864V4.41367C2.14515 3.1652 3.16631 2.14404 4.41478 2.14404H25.5875C26.836 2.14404 27.8571 3.1652 27.8571 4.41367V25.5864ZM27.8571 10.2224L25.535 8.658C25.5817 8.4575 25.6096 8.24884 25.6096 8.03318C25.6096 6.52476 24.3868 5.30077 22.8772 5.30077C21.3676 5.30077 20.1448 6.5236 20.1448 8.03318C20.1448 8.62653 20.336 9.17441 20.6577 9.62321L15.637 17.3005C15.433 17.2516 15.2197 17.2224 15.0006 17.2224C14.3279 17.2224 13.7136 17.4661 13.2368 17.8694L9.87611 15.9355C9.8936 15.8119 9.90525 15.6872 9.90525 15.559C9.90525 14.0506 8.68243 12.8266 7.17284 12.8266C5.66325 12.8266 4.44043 14.0494 4.44043 15.559C4.44043 15.9332 4.5162 16.2899 4.65258 16.6151L2.14282 18.943V21.4201L5.87075 17.9615C6.25776 18.1713 6.70073 18.2902 7.17167 18.2902C7.89441 18.2902 8.54954 18.0081 9.03797 17.5488L12.3171 19.4361C12.2845 19.604 12.267 19.7765 12.267 19.9537C12.267 21.4621 13.4898 22.6849 14.9994 22.6849C16.509 22.6849 17.7318 21.4621 17.7318 19.9537C17.7318 19.3254 17.5173 18.7483 17.1606 18.2867L22.1463 10.663C22.3795 10.7271 22.6231 10.7644 22.8761 10.7644C23.509 10.7644 24.0896 10.5476 24.5535 10.1862L27.8559 12.4116L27.8571 10.2224Z"
                className="nav-color-base-2"
              />
            </svg>
            <p>Report</p>
          </NavLink>
        )} */}
      </ul>
      <button
        className="logout outline"
        onClick={() => setShowPopup({ ...showPopup, logout: true })}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M14.8993 25C9.41353 25 4.96643 20.5229 4.96643 15C4.96643 9.47715 9.41353 5 14.8993 5V25Z"
            fill="#1C274C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4489 10.5871C20.0853 10.9532 20.0853 11.5468 20.4489 11.9129L22.584 14.0625H12.4161C11.9018 14.0625 11.4849 14.4822 11.4849 15C11.4849 15.5177 11.9018 15.9375 12.4161 15.9375H22.584L20.4489 18.0871C20.0853 18.4532 20.0853 19.0467 20.4489 19.4129C20.8126 19.779 21.4021 19.779 21.7658 19.4129L25.4906 15.6629C25.8543 15.2967 25.8543 14.7032 25.4906 14.3371L21.7658 10.5871C21.4021 10.221 20.8126 10.221 20.4489 10.5871Z"
            fill="#1C274C"
          />
        </svg>
        <p>Logout</p>
      </button>
      {showPopup.logout && (
        <PopupBox
          formName=" row"
          color="danger"
          close={() => setShowPopup({ ...showPopup, logout: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            { type: "danger", name: "Logout", func: handleLogout },
          ]}
        >
          <svg
            width="60px"
            height="60px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.62219V17.245C22 18.3579 21.2857 19.4708 20.1633 19.8754L15.0612 21.7977C14.7551 21.8988 14.449 22 14.0408 22C13.5306 22 12.9184 21.7977 12.4082 21.4942C12.2041 21.2918 11.898 21.0895 11.7959 20.8871H7.91837C6.38776 20.8871 5.06122 19.6731 5.06122 18.0544V17.0427C5.06122 16.638 5.36735 16.2333 5.87755 16.2333C6.38776 16.2333 6.69388 16.5368 6.69388 17.0427V18.0544C6.69388 18.7626 7.30612 19.2684 7.91837 19.2684H11.2857V4.69997H7.91837C7.20408 4.69997 6.69388 5.20582 6.69388 5.91401V6.9257C6.69388 7.33038 6.38776 7.73506 5.87755 7.73506C5.36735 7.73506 5.06122 7.33038 5.06122 6.9257V5.91401C5.06122 4.39646 6.28572 3.08125 7.91837 3.08125H11.7959C12 2.87891 12.2041 2.67657 12.4082 2.47423C13.2245 1.96838 14.1429 1.86721 15.0612 2.17072L20.1633 4.09295C21.1837 4.39646 22 5.50933 22 6.62219Z"
              fill="#030D45"
            />
            <path
              d="M4.85714 14.8169C4.65306 14.8169 4.44898 14.7158 4.34694 14.6146L2.30612 12.5912C2.20408 12.49 2.20408 12.3889 2.10204 12.3889C2.10204 12.2877 2 12.1865 2 12.0854C2 11.9842 2 11.883 2.10204 11.7819C2.10204 11.6807 2.20408 11.5795 2.30612 11.5795L4.34694 9.55612C4.65306 9.25261 5.16327 9.25261 5.46939 9.55612C5.77551 9.85963 5.77551 10.3655 5.46939 10.669L4.7551 11.3772H8.93878C9.34694 11.3772 9.7551 11.6807 9.7551 12.1865C9.7551 12.6924 9.34694 12.7936 8.93878 12.7936H4.65306L5.36735 13.5017C5.67347 13.8052 5.67347 14.3111 5.36735 14.6146C5.26531 14.7158 5.06122 14.8169 4.85714 14.8169Z"
              fill="#030D45"
            />
          </svg>

          <div className="right">
            <h3>Are you sure?</h3>
            <p>Do you really want to Logout?</p>
          </div>
        </PopupBox>
      )}
    </nav>
  );
};

function NavLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default NavBar;
