import { useData } from "../components/dataProvider/dataProvider";
import Lottery from "./lottery";
import SubTicket from "./subTicket";
import Report from "./report";

const Tickets = ({ authKey }) => {
  const { isTicket } = useData();

  return <div>{isTicket === 0 ? <Lottery /> : isTicket === 1 ? <SubTicket /> : <Report />}</div>;
};

export default Tickets;
