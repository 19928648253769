import { useEffect, useRef, useState } from "react";
import ListDisplay from "../components/listDisplay/listDisplay";
import "./employees.css";
import useMyFetch from "../components/fetch/fetch";
import PopupBox from "../components/popup/popup";
import { InputBox2, TextArea } from "../components/inputBox/inputBox";
import toast from "react-hot-toast";
import { useData } from "../components/dataProvider/dataProvider";

const BlackLists = () => {
  const { post } = useMyFetch();
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState({
    newBlacklist: false,
    editBlacklist: false,
    deleteBlacklist: false,
  });
  const [loading, setLoading] = useState(true);
  const { setLoadingScreen } = useData();

  useEffect(() => {
    post("blacklist").then((response) => {
      if (response.status !== "success") {
        toast.error(response.message);
        return;
      }
      if (Array.isArray(response.data)) {
        setData(response.data);
        setLoading(false);
      } else {
        const dataArray = Object.keys(response.data).map((key) => data[key]);
        setData(dataArray);
      }
    });
  }, []);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // const [newPassword, setNewPassword] = useState(1);

  const nameInput = useRef(null);
  const phoneInput = useRef(null);

  function KeyPressHandler(event, nextRef, isLast = false) {
    if (event.target.name === "phone") {
      if (!event.key.match(/^\d$/)) {
        event.preventDefault();
      }
    }
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value !== "") {
        if (isLast) {
          if (showPopup.editBlacklist) {
            confirmEdit();
            return;
          }
          ConfirmNewBlacklist();
          return;
        }
        if (nextRef && nextRef.current) {
          nextRef.current.focus();
        }
      }
    }
  }

  const inputCheck = () => {
    if (name === "") {
      toast.error("Name Cannot be Empty");
      nameInput.current.focus();
      return false;
    }
    if (phoneNumber === "") {
      toast.error("Phone Number Cannot be Empty");
      phoneInput.current.focus();
      return false;
    }
    return true;
  };

  const newBlacklist = () => {
    console.log("new Employee");
    // setNewPassword(1);
    setName("");
    setPhoneNumber("");
    setShowPopup({ ...showPopup, newBlacklist: true });
  };
  const ConfirmNewBlacklist = () => {
    if (!inputCheck()) return;
    setLoadingScreen(true);
    console.log("Confirm new Employee");
    post("blacklist/add", { name, ph_no: phoneNumber }).then((response) => {
      setLoadingScreen(false);
      if (response.status !== "success") {
        toast.error(response.message);
        if (response.message === "A Blacklist with that phone number \n already exist")
          phoneInput.current.focus();
        return;
      }
      setData([...data, response.data]);
      setShowPopup({ ...showPopup, newBlacklist: false });
    });
  };

  const handleEdit = (id) => {
    console.log("Edit action triggered for ID:", id);
    setId(id);
    const blacklist = data.filter((item) => item.id === id)[0];
    console.log(blacklist);
    setName(blacklist.name);
    setPhoneNumber(blacklist.ph_no);
    // setNewPassword(0);
    setShowPopup({ ...showPopup, editBlacklist: true });
  };
  const confirmEdit = () => {
    console.log("confirm Edit");
    if (!inputCheck()) return;
    setLoadingScreen(true);
    post("blacklist/edit", { id, name, ph_no: phoneNumber }).then((response) => {
      setLoadingScreen(false);
      if (response.status !== "success") {
        toast.error(response.message);
        if (response.message === "Phone Number already exist") phoneInput.current.focus();
        return;
      }
      setData(data.map((item) => (item.id === id ? response.data : item)));
      setShowPopup({ ...showPopup, editBlacklist: false });
    });
  };

  const handleDelete = (id) => {
    console.log("Delete action triggered for", id);
    setId(id);
    setShowPopup({ ...showPopup, deleteBlacklist: true });
  };
  const ConfirmDeleteBlacklist = () => {
    console.log("Confirm Delete Trigger for", id);
    setLoadingScreen(true);
    post("blacklist/delete", { id: id }).then((response) => {
      setLoadingScreen(false);
      if (response.status !== "success") {
        toast.error(response.message);
        return;
      }
      setData(data.filter((item) => item.id !== id));
      setShowPopup({ ...showPopup, deleteBlacklist: false });
    });
  };

  return (
    <div className="pages employees">
      <div className="page-title">
        <h2>Blacklist</h2>
        <div className="action">
          <button className="secondary" onClick={newBlacklist}>
            <svg width="16px">
              <use xlinkHref="#plus-icon" />
            </svg>
            <p>Add Blacklist</p>
          </button>
        </div>
      </div>

      <ListDisplay
        titleList={["Name", "Phone Number"]}
        size={["name", "ph-no"]}
        data={data.map((blacklist) => ({
          id: blacklist.id,
          Name: blacklist.name,
          "Phone Number": blacklist.ph_no.replace(/(\d{2})(\d{7})/, "$1 $2"),
        }))}
        actions={[
          { type: "edit", handler: handleEdit },
          { type: "delete", handler: handleDelete },
        ]}
        is_loading={loading}
      />

      {(showPopup.editBlacklist || showPopup.newBlacklist) && (
        <PopupBox
          formName="new-Blacklist"
          color="primary"
          close={() => setShowPopup({ ...showPopup, newBlacklist: false, editBlacklist: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            {
              type: "success",
              name: showPopup.editBlacklist ? "Save" : "Create",
              func: showPopup.editBlacklist ? confirmEdit : ConfirmNewBlacklist,
            },
          ]}
        >
          <h1>{showPopup.newBlacklist ? "Add Blacklist" : "Edit Blacklist"}</h1>
          <svg title="employee-icon" width="88px" height="88px">
            <use xlinkHref="#employee-icon" />
          </svg>
          <InputBox2
            type="text"
            name="name"
            display="Name"
            value={name}
            setValue={setName}
            isRequired={true}
            ref={nameInput}
            onKeyPress={(e) => KeyPressHandler(e, phoneInput)}
            placeholder="Enter Customer Name"
            autoComplete="off"
          />
          <InputBox2
            type="text"
            name="phone"
            display="Phone Number"
            value={phoneNumber}
            setValue={setPhoneNumber}
            isRequired={true}
            ref={phoneInput}
            onKeyPress={(e) => KeyPressHandler(e, null, true)}
            placeholder="Enter Username"
            autoComplete="off"
          />
        </PopupBox>
      )}
      {showPopup.deleteBlacklist && (
        <PopupBox
          formName="delete-Blacklist row"
          color="danger"
          close={() => setShowPopup({ ...showPopup, deleteBlacklist: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            {
              type: "danger",
              name: "Delete",
              func: ConfirmDeleteBlacklist,
            },
          ]}
        >
          <div className="trash-can">
            <svg viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.31258 25.8345C2.31258 27.5489 3.76571 28.9516 5.54175 28.9516H18.4584C20.2345 28.9516 21.6876 27.5489 21.6876 25.8345V7.13172H2.31258V25.8345ZM23.3022 2.45603H17.6511L16.0365 0.897461H7.96362L6.34904 2.45603H0.697998V5.57315H23.3022V2.45603Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="right">
            <h3>Are you sure?</h3>
            <p>
              Do you really want to delete this Blacklist?
              <br /> This process cannot be undone.
            </p>
          </div>
        </PopupBox>
      )}
    </div>
  );
};

export default BlackLists;
