import React, { useState, useEffect, useRef, useCallback } from "react";
import "./listDisplay.css";
import ReactLoading from "react-loading";

const ListDisplay = ({
  titleList = [],
  data = [],
  size = [],
  actions = [],
  max_height = "calc(100% - 48px)",
  is_loading = false,
}) => {
  const actionCount = actions.length;
  let current_count = 1;

  const [displayData, setDisplayData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(20);
  const observer = useRef();

  const lastRowRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisibleCount((prevCount) => prevCount + 5);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    setDisplayData(data.slice(0, visibleCount));
  }, [data, visibleCount]);

  // Helper function to get the style or class for a column based on the size value
  const getColumnStyle = (size) => {
    if (typeof size === "number") {
      return { width: `${size}px` };
    } else if (typeof size === "string") {
      return { className: size };
    }
    return {};
  };

  // Render the action with predefined SVG icon or custom button
  const renderAction = (action, id) => {
    const actionType = action.type;
    const handleClick = () => action.handler(id);

    switch (actionType) {
      case "view":
        return (
          <svg onClick={handleClick} title="View">
            <use xlinkHref="#view-icon" />
          </svg>
        );
      case "edit":
        return (
          <svg onClick={handleClick} title="Edit">
            <use xlinkHref="#edit-icon" />
          </svg>
        );
      case "delete":
        return (
          <svg onClick={handleClick} title="Delete">
            <use xlinkHref="#delete-icon" />
          </svg>
        );
      default:
        return (
          <button onClick={handleClick} className="success-outline">
            {actionType}
          </button>
        );
    }
  };

  return (
    <div className="list-display" style={{ maxHeight: max_height }}>
      <div className="list-title">
        <p className="no"></p>
        {titleList.map((title, index) => {
          const { width, className } = getColumnStyle(size[index]);
          return (
            <p key={index} className={className || ""} style={width ? { width } : {}}>
              {title}
            </p>
          );
        })}
        {actionCount > 0 && <p className={`action action${actionCount}`}>Action</p>}
      </div>

      {is_loading ? (
        <div className="loading-indicator">
          <ReactLoading type="bars" color="var(--light)" height={"40px"} width={"40px"} />
        </div>
      ) : data.length === 0 ? (
        <div style={{ padding: "40px", margin: "auto" }}>
          <svg title="No Data" width="240" height="240" style={{ padding: "20px" }}>
            <use xlinkHref="#nodata-icon" />
          </svg>
          <h2>No Data to Show</h2>
        </div>
      ) : (
        <div className="list-content">
          {displayData.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="list-row"
              ref={rowIndex === displayData.length - 1 ? lastRowRef : null}
            >
              <p className="no">{current_count++}.</p>
              {titleList.map((title, colIndex) => {
                const { width, className } = getColumnStyle(size[colIndex]);
                return (
                  <p key={colIndex} className={className || ""} style={width ? { width } : {}}>
                    {row[title]}
                  </p>
                );
              })}
              {actionCount > 0 && (
                <div className={`action action${actionCount}`}>
                  {actions.map((action, actionIndex) => (
                    <div key={actionIndex}>{renderAction(action, row.id)}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListDisplay;

// const actions = [
//   { type: "view", handler: handleView },
//   { type: "edit", handler: handleEdit },
//   { type: "delete", handler: handleDelete },
//   { type: "Custom", handler: handleCustom },
// ];
