import { Outlet } from "react-router-dom";
import NavBar from "../components/navbar/navBar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "../components/header/Header";
import ReactLoading from "react-loading";

import "./home.css";
import { useData } from "../components/dataProvider/dataProvider";
import useMyFetch from "../components/fetch/fetch";
import toast from "react-hot-toast";

const Home = () => {
  const navigate = useNavigate();
  const { deviceAuth, userInfo, loadingScreen, menuExtended } = useData();
  const { authorize } = useMyFetch();

  useEffect(() => {
    if (!deviceAuth.key) {
      navigate("/authorize");
    } else {
      authorize();
    }
    if (!userInfo.key) {
      navigate("/login");
    }
  }, []);

  return (
    <main className={menuExtended ? "extended" : ""}>
      <Header userInfo={userInfo} />
      <NavBar />
      <Outlet />
      {loadingScreen && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#5554",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fffd",
              width: "200px",
              height: "120px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "3px 3px 5px #555345",
            }}
          >
            <ReactLoading type="bars" color="var(--primary)" height={"60px"} width={"60px"} />
          </div>
        </div>
      )}
    </main>
  );
};

export default Home;
