import React, { forwardRef, useEffect, useState } from "react";
import logo from "./logo.png";
import car from "./car.png";
import "./ticket.css";

const TicketVoucher = forwardRef(
  ({ time, number, name, phone, address, image = car, price, date, setImageLoading }, ref) => {
    const convertToMyanmarNumerals = (num) => {
      const myanmarNumerals = ["၀", "၁", "၂", "၃", "၄", "၅", "၆", "၇", "၈", "၉"];
      return num
        .toString()
        .split("")
        .map((digit) => myanmarNumerals[digit])
        .join("");
    };

    const [img1, setImg1] = useState(true);
    const [img2, setImg2] = useState(true);

    useEffect(() => {
      if (!img1 && !img2) setImageLoading(false);
    }, [img1, img2, setImageLoading]);

    return (
      <div className="ticketVoucher" ref={ref}>
        <div className="title">
          <img
            src={logo}
            alt="Logo"
            className="logo"
            onLoad={() => setImg2(false)}
            onError={() => setImg2(false)}
          />
          <h1 className="time">
            <sup>* </sup>({convertToMyanmarNumerals(time)}) ကြိမ်မြောက် {""}ကားကံစမ်းမဲ{" "}
            <sup> *</sup>
          </h1>
          <div className="ticket">
            <h1>
              Ticket No: <span>{number}</span>
            </h1>
          </div>
        </div>
        <div className="body">
          <div className="cus-info">
            <div>
              <p>နာမည်</p>
              <p>:</p>
              <p>{name}</p>
            </div>
            <div>
              <p>လိပ်စာ</p>
              <p>:</p>
              <p className="address">{address}</p>
            </div>
            <div>
              <p>ဖုန်းနံပါတ်</p>
              <p>:</p>
              <p>{phone}</p>
            </div>
          </div>
          <div className="pic-container">
            <img src={image} alt="" onLoad={() => setImg1(false)} onError={() => setImg1(false)} />
            <p>{Number(price).toLocaleString()} ကျပ်</p>
          </div>
        </div>
        <div className="ph-no">
          <p>09-777 772 142</p>
          <p>/</p>
          <p>09-515 1886</p>
          <p>/</p>
          <p>09-788 887 247</p>
          <p>/</p>
          <p>09-256 514 956</p>
        </div>
        <div className="date">
          <p>( {date} ) ရက်နေ့တွင် ဖွင့်လှစ်ပါမည်။</p>
        </div>
      </div>
    );
  }
);

export default TicketVoucher;
