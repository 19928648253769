// useMyFetch.js
import { useNavigate } from "react-router-dom";
import { useData } from "../dataProvider/dataProvider"; // Adjust import path as per your file structure
import toast from "react-hot-toast";

const domain = "https://lottery.binarysitsolution.shop/server/models/";
// const domain = "http://localhost/lottery/server/models/";

function decodeHTMLEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

function decodeEntitiesRecursive(data) {
  if (typeof data === "string") {
    return decodeHTMLEntities(data);
  } else if (Array.isArray(data)) {
    return data.map(decodeEntitiesRecursive);
  } else if (typeof data === "object" && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = decodeEntitiesRecursive(data[key]);
      return acc;
    }, {});
  }
  return data;
}

const useMyFetch = () => {
  const { userInfo, setDeviceAuth, deviceAuth, setConnectionQuality, setPingAmount } = useData();
  const navigate = useNavigate();

  const ping = async () => {
    const startTime = Date.now();
    return fetch(domain + "ping")
      .then((response) => {
        const endTime = Date.now();
        if (response.ok) {
          const ping = endTime - startTime;
          setPingAmount(ping);
          if (ping < 50) {
            setConnectionQuality(4);
          } else if (ping < 100) {
            setConnectionQuality(3);
          } else if (ping < 200) {
            setConnectionQuality(2);
          } else {
            setConnectionQuality(1);
          }
        } else {
          setConnectionQuality(0);
        }
      })
      .catch((error) => {
        setConnectionQuality(0);
      });
  };

  const post = async (where, body = {}) => {
    // await waitForInitialization();
    let formData = new FormData();
    formData.append("authKey", userInfo.key);
    formData.append("user_id", userInfo.id);
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    return fetch(domain + where, {
      method: "POST",
      body: formData,
    })
      .then((server_response) => server_response.json())
      .then((response) => {
        response = decodeEntitiesRecursive(response);
        console.log(response);
        if (response.message === "Key expired" || response.message === "Key Doesn't Exist") {
          response.message = "Session TimeOut \n Please Login Again";
          navigate("/login");
          return response;
        }
        return response;
      })
      .catch((error) => {
        const errorMessage =
          error.message === "Failed to fetch" ? "Cannot Connect to Server" : error.message;
        return { status: "error", message: errorMessage };
      });
  };

  const post2 = async (where, body = {}) => {
    let formData = new FormData();
    formData.append("authKey", userInfo.key);
    formData.append("user_id", userInfo.id);
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    return fetch(domain + where, {
      method: "POST",
      body: formData,
    })
      .then((server_response) => server_response.text())
      .then((response) => {
        response = decodeEntitiesRecursive(response);
        if (response.message === "Key expired" || response.message === "Key Doesn't Exist") {
          response.message = "Session TimeOut \n Please Login Again";
          navigate("/login");
          return response;
        }
        return response;
      })
      .catch((error) => {
        const errorMessage =
          error.message === "Failed to fetch" ? "Cannot Connect to Server" : error.message;
        return { status: "error", message: errorMessage };
      });
  };

  const authorize = async (body = {}) => {
    // await waitForInitialization();
    let formData = new FormData();
    if (body && body.key) {
      formData.append("key", body.key);
    } else {
      console.log("hello");
      console.log(deviceAuth.key);
      console.log(deviceAuth.id);
      formData.append("key", deviceAuth.key);
      formData.append("device_id", deviceAuth.id);
    }

    return fetch(domain + "authorize", {
      method: "POST",
      body: formData,
    })
      .then((server_response) => server_response.json())
      .then((response) => {
        response = decodeEntitiesRecursive(response);
        console.log(response);
        if (response.status !== "success") {
          if (response.message === "Device is not authorized") {
            toast.error(response.message);
            setDeviceAuth({ key: "", id: "" });
            navigate("/authorize");
          }
        }
        return response;
      })
      .catch((error) => {
        const errorMessage =
          error.message === "Failed to fetch" ? "Cannot Connect to Server" : error.message;
        return { status: "error", message: errorMessage };
      });
  };

  const get = async (where) => {
    return fetch(domain + where, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.key}`, // Example of adding auth headers
      },
    })
      .then((server_response) => server_response.json())
      .then((response) => response)
      .catch((error) => {
        const errorMessage =
          error.message === "Failed to fetch" ? "Cannot Connect to Server" : error.message;
        return { status: "error", message: errorMessage };
      });
  };

  return { post, post2, get, ping, authorize };
};

export default useMyFetch;
