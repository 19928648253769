// import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div>
      <h1 style={{ padding: "20px", fontSize: "32px" }}>Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
