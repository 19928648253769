import React, { useEffect, useState } from "react";
import "./wifiIcon.css";
import { useData } from "../dataProvider/dataProvider";

const WifiIcon = () => {
  const { connectionQuality, pingAmount } = useData();
  const [color, setColor] = useState({
    text: "#03ac2d",
    bar1: "#03ac2d",
    bar2: "#03ac2d",
    bar3: "#03ac2d",
    bar4: "#03ac2d",
  });

  useEffect(() => {
    switch (connectionQuality) {
      case 4:
        setColor({
          text: "#03ac2d",
          bar1: "#03ac2d",
          bar2: "#03ac2d",
          bar3: "#03ac2d",
          bar4: "#03ac2d",
        });
        break;
      case 3:
        setColor({
          text: "#03ac2d",
          bar1: "#03ac2d",
          bar2: "#03ac2d",
          bar3: "#03ac2d",
          bar4: "#888",
        });
        break;
      case 2:
        setColor({ text: "#f3aa18", bar1: "#f3aa18", bar2: "#f3aa18", bar3: "#888", bar4: "#888" });
        break;
      case 1:
        setColor({ text: "#ce181e", bar1: "#ce181e", bar2: "#888", bar3: "#888", bar4: "#888" });
        break;
      default:
        setColor({
          text: "#ce181e",
          bar1: "#ce181e",
          bar2: "#ce181e",
          bar3: "#ce181e",
          bar4: "#ce181e",
        });
        break;
    }
  }, [connectionQuality]);
  return (
    <div id="ping-status">
      <p style={{ color: color.text }}>{connectionQuality ? pingAmount + "ms" : "999ms"}</p>

      <svg viewBox="0 0 256 256" fill="none" id="wifi-icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.366 180.515C117.863 168.018 138.125 168.018 150.621 180.515C154.787 184.681 154.787 191.435 150.621 195.6L135.536 210.685C131.371 214.85 124.617 214.85 120.451 210.685L105.366 195.6C101.201 191.435 101.201 184.681 105.366 180.515Z"
          fill={color.bar1}
          stroke={color.bar1}
          strokeWidth="4.608"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.2833 165.429C111.111 144.6 144.88 144.6 165.708 165.429C169.874 169.594 176.627 169.594 180.793 165.429C184.959 161.263 184.959 154.51 180.793 150.344C151.634 121.185 104.357 121.185 75.1983 150.344C71.0327 154.51 71.0327 161.263 75.1983 165.429C79.3639 169.594 86.1176 169.594 90.2833 165.429Z"
          fill={color.bar2}
          stroke={color.bar2}
          strokeWidth="4.608"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.2092 135.259C97.6995 97.7692 158.483 97.7692 195.974 135.259C200.14 139.426 206.893 139.426 211.059 135.259C215.224 131.094 215.224 124.34 211.059 120.175C165.237 74.3533 90.9458 74.3533 45.1242 120.175C40.9586 124.34 40.9586 131.094 45.1242 135.259C49.2898 139.426 56.0436 139.426 60.2092 135.259Z"
          fill={color.bar3}
          stroke={color.bar3}
          strokeWidth="4.608"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0849 104.948C84.2377 50.7952 172.037 50.7952 226.189 104.948C230.355 109.113 237.109 109.113 241.274 104.948C245.44 100.782 245.44 94.0285 241.274 89.863C178.79 27.379 77.4839 27.379 15 89.863C10.8345 94.0285 10.8345 100.782 15 104.948C19.1657 109.113 25.9194 109.113 30.0849 104.948Z"
          fill={color.bar4}
          stroke={color.bar4}
          strokeWidth="4.608"
        />
      </svg>
    </div>
  );
};

export default WifiIcon;
