import { useRef, useState } from "react";
import "./login.css";
import { InputBox } from "../components/inputBox/inputBox";
import { useNavigate } from "react-router-dom";
import { useData } from "../components/dataProvider/dataProvider";
import toast from "react-hot-toast";
import useMyFetch from "../components/fetch/fetch";

const Authorize = () => {
  const navigate = useNavigate();
  const { authorize } = useMyFetch();
  const { setDeviceAuth } = useData();
  const [key, setKey] = useState("");
  const keyInput = useRef(null);
  const form = useRef(null);

  function KeyPressHandler(event) {
    if (event.key === "Enter" && event.target.value !== "") {
      submitHandler(event);
    }
  }

  function submitHandler(event) {
    event.preventDefault();
    console.log("Authorize form Submit");

    authorize({ key }).then((response) => {
      if (response.status !== "success") {
        toast.error(response.message);
        keyInput.current.focus();
        return;
      }
      const { data } = response;
      setDeviceAuth({ key: data.key, id: data.id });
      toast.success("Device Authorization success");
      navigate("/login");
    });
  }

  return (
    <main className="login-page">
      <form className="login-form" onSubmit={submitHandler} method="POST" ref={form}>
        <div className="deco"></div>

        <h1>Enter License Key</h1>
        <InputBox
          type="text"
          name="key"
          display="License Key"
          value={key}
          setValue={setKey}
          isRequired={true}
          ref={keyInput}
          onKeyPress={(e) => KeyPressHandler(e)}
        />
        <button className="success">Authorize</button>
      </form>
    </main>
  );
};

export default Authorize;
